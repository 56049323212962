import { EndorsementPremiumChange } from '@root/@types/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { z } from 'zod';

import {
  blockVariationSchema,
  createResourceBlockSchema,
  linkedDatapointSchema,
  visibilityConfigSchema,
} from './blocks';
import { clauseTypeSchema, rootResourceStatusSchema, submissionStatusSchema, templateStatusSchema } from './root';
import { createResourceSectionSchema } from './sections';

export const createResourcePayloadSchema = z
  .object({
    branding: z.string().optional(),
    class_of_business: z.string().optional(),
    copy_from_submission: z.string().optional(),
    copy_from_template: z.string().optional(),
    data_items: z.object({}).optional(),
    name: z.string(),
    schema_id: z.string().optional(),
    sections: z
      .array(
        createResourceSectionSchema.extend({ blocks: z.array(createResourceBlockSchema.omit({ section_id: true })) }),
      )
      .optional(),
    status: rootResourceStatusSchema.optional(),
    umr: z.string().optional(),
    teams: z.array(z.number()).optional(),
  })
  .strict();

export const createTenantSchema = z
  .object({
    name: z.string(),
    parent_id: z.string(),
  })
  .strict();

export const getExternalSubmissionSchema = z.object({}).partial().strict();

export const patchExternalSubmissionSchema = z
  .object({
    name: z.string(),
  })
  .partial();

export const putExternalSubmissionSchema = z
  .object({
    name: z.string(),
  })
  .partial();

export const postExternalSubmissionSchema = z
  .object({
    created_by: z.string().optional(),
    template_id: z.string().optional(),
    submission_id: z.string().optional(),
    external_id: z.string().optional(),
    name: z.string(),
  })
  .refine((data) => data.template_id || data.submission_id, 'Either template_id or submission_id should be provided.');

export const addBlockFromLibraryPayloadSchema = z
  .object({
    blockLibraryId: z.string(),
    canDelete: z.boolean().optional(),
    canDeleteOnTemplate: z.boolean().optional(),
    canEdit: z.boolean().optional(),
    order: z.number().optional(),
    sectionId: z.string(),
    selectedVariationId: z.string().optional(),
    visibility: visibilityConfigSchema.optional(),
  })
  .strict();

export const addNewCommentPayloadSchema = z
  .object({
    blockId: z.string().optional(),
    content: z.string(),
    threadId: z.string().optional(),
  })
  .strict();

export const changeBaseResourcePayloadSchema = z
  .object({
    sourceResourceId: z.string(),
  })
  .strict();

export const createRevisionPayloadSchema = z
  .object({
    revisionName: z.string(),
  })
  .strict();

export const deleteResourceBlockPayloadSchema = z
  .object({
    blockId: z.string(),
  })
  .strict();

export const deleteResourceSectionPayloadSchema = z
  .object({
    sectionId: z.string(),
  })
  .strict();

export const reorderResourceBlockPayloadSchema = z
  .object({
    blockId: z.string(),
    reorder: z.object({
      destinationIndex: z.number(),
      destinationSectionId: z.string().optional(),
    }),
  })
  .strict();

export const reorderResourceSectionPayloadSchema = z
  .object({
    sectionId: z.string(),
    reorder: z.object({
      destinationIndex: z.number(),
    }),
  })
  .strict();

export const resolveCommentsThreadPayloadSchema = z
  .object({
    threadId: z.string(),
  })
  .strict();

export const restoreRevisionPayloadSchema = z
  .object({
    revisionId: z.string(),
  })
  .strict();

export const updateResourceBlockPayloadSchema = z
  .object({
    blockId: z.string(),
    block: z
      .object({
        blockLibraryId: z.string().optional(),
        canDelete: z.boolean().optional(),
        canDeleteOnTemplate: z.boolean().optional(),
        canEdit: z.boolean().optional(),
        clause_reference: z.string().optional(),
        clause_type: clauseTypeSchema.optional(),
        content: z.string(),
        copied_from_template_id: z.string().optional(),
        helperText: z.string().optional(),
        linkedDatapoints: z.array(linkedDatapointSchema),
        name: z.string(),
        repeaterIds: z.array(z.string()),
        section_id: z.string(),
        selectedVariationId: z.string(),
        shouldInsertPageBreak: z.boolean().optional(),
        title: z.string(),
        type: z.string(),
        variations: z.array(blockVariationSchema).optional(),
        visibility: visibilityConfigSchema.optional(),
      })
      .partial(),
  })
  .strict();

export const updateResourceDataBaseSchema = z
  .object({
    branding: z.string().optional(),
    class_of_business: z.enum(ALL_CLASSES_OF_BUSINESS as unknown as readonly [string, ...string[]]).optional(),
    data_items: z.record(z.string(), z.any()).optional(),
    name: z.string().optional(),
    layout: z.array(z.string()).optional(),
    revision_name: z.string().optional(),
    status: rootResourceStatusSchema.optional(),
    tags: z.array(z.string()).optional(),
    teams: z.array(z.number()).optional(),
  })
  .strict();

export const updateSubmissionDataPayloadSchema = updateResourceDataBaseSchema
  .extend({
    status: submissionStatusSchema.optional(),
  })
  .strict();

export const updateTemplateDataPayloadSchema = updateResourceDataBaseSchema
  .extend({
    status: templateStatusSchema.optional(),
  })
  .strict();

export const updateEndorsementDataPayloadSchema = updateResourceDataBaseSchema.extend({
  additional_premium: z.object({ amount: z.number(), currency: z.string() }).nullable().optional(),
  effective_date: z.string().datetime({ local: true }).optional(),
  endorsement_amendment_text: z.string().optional(),
  endorsement_summary: z.string().optional(),
  expiry_date: z.string().datetime({ local: true }).optional(),
  premium_change: z.nativeEnum(EndorsementPremiumChange).optional(),
  reason: z.string().optional(),
  reference: z.string().optional(),
  type: z.string().optional(),
});

export const updateResourceDataPayloadSchema = z.union([
  updateSubmissionDataPayloadSchema,
  updateTemplateDataPayloadSchema,
  updateEndorsementDataPayloadSchema,
]);

export const updateResourceSectionPayloadSchema = z
  .object({
    sectionId: z.string(),
    section: createResourceSectionSchema.partial(),
  })
  .strict();

export const updateBlockInLibraryPayloadSchema = createResourceBlockSchema
  .omit({
    blockLibraryId: true,
    canDeleteOnTemplate: true,
    copied_from_template_id: true,
    extractionCandidateId: true,
    prevBlockOrder: true,
    visibility: true,
  })
  .extend({
    canEditOnTemplate: z.boolean().optional(),
    class_of_business: z.string().optional(),
    deleted_at: z.nullable(z.string().datetime({ offset: true })).optional(),
  })
  .partial()
  .strict();
