import { z } from 'zod';

import { clauseTypeSchema } from './root';

export const linkedDatapointSchema = z.object({ id: z.string(), isRequired: z.boolean() }).strict();
export const blockTypeSchema = z.enum(['clause', 'generic', 'mrc-heading']);

export const blockVariationSchema = z
  .object({
    id: z.string(),
    title: z.string(),
    content: z.string(),
    linkedDatapoints: z.array(linkedDatapointSchema),
    repeaterIds: z.array(z.string()),
    name: z.string().optional(),
  })
  .strict();

export const blockVisibilityModeSchema = z.enum(['SHOW', 'HIDE', 'AUTOSELECT']);
export const blockVisibilityMatchSchema = z.enum(['AND', 'OR']);
export const blockVisibilityOperatorSchema = z.enum(['IS', 'IS NOT', 'IS EMPTY', 'IS NOT EMPTY']);

export const blockVisibilityConditionSchema = z
  .object({
    field: z.string(),
    operator: blockVisibilityOperatorSchema,
    value: z.union([z.string(), z.array(z.string()), z.number(), z.boolean()]).optional(),
  })
  .strict();

export const blockLevelVisibilityConfigSchema = z
  .object({
    conditions: z.array(blockVisibilityConditionSchema),
    match: blockVisibilityMatchSchema.optional(),
    mode: blockVisibilityModeSchema,
  })
  .strict();

export const variationLevelVisibilityConfigSchema = z
  .object({
    conditions: z.array(blockVisibilityConditionSchema),
    id: z.string(),
    match: blockVisibilityMatchSchema.optional(),
    mode: blockVisibilityModeSchema,
  })
  .strict();

export const visibilityConfigSchema = z
  .object({
    block: blockLevelVisibilityConfigSchema.optional(),
    variations: z.array(z.union([variationLevelVisibilityConfigSchema, z.null()])).optional(),
  })
  .strict();

export const createResourceBlockSchema = z
  .object({
    blockLibraryId: z.string().optional(),
    canDelete: z.boolean().optional(),
    canDeleteOnTemplate: z.boolean().optional(),
    canEdit: z.boolean().optional(),
    clause_reference: z.string().optional(),
    clause_type: clauseTypeSchema.optional(),
    content: z.string(),
    copied_from_template_id: z.string().optional(),
    extractionCandidateId: z.string().optional(),
    helperText: z.string().optional(),
    linkedDatapoints: z.array(linkedDatapointSchema).optional(),
    mrc_heading_type: z.string().optional(),
    name: z.string(),
    prevBlockOrder: z.number().optional(),
    repeaterIds: z.array(z.string()).optional(),
    section_id: z.string(),
    selectedVariationId: z.string().optional(),
    shouldInsertPageBreak: z.boolean().optional(),
    title: z.string().optional(),
    type: blockTypeSchema.optional(),
    variations: z.array(blockVariationSchema).optional(),
    visibility: visibilityConfigSchema.optional(),
  })
  .strict();
