import { StandardisedSectionName } from '@root/@types/types';
import { z } from 'zod';

export const createResourceSectionSchema = z
  .object({
    label: z.string(),
    layout: z.array(z.string()).optional(),
    parentSectionId: z.string().optional(),
    standardised_name: z.nativeEnum(StandardisedSectionName).optional(),
    should_reset_page_counter: z.boolean().optional(),
    should_hide_title_in_pdf: z.boolean().optional(),
  })
  .strict();
